<template lang="pug">
	extends ./table.pug

	block tableTH
		template(v-slot:cell(info)='data')
			.vuetable__info(:id="!isMobile && Object.keys(data.item).length != 0 ? `info-${data.item.id}` : ''" @click="isMobile && Object.keys(data.item).length != 0 ? $bvModal.show(`info-${data.item.id}`) : ''")
				svg(width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg")
					path(d="M16.5 9.5C16.5 5.375 13.125 2 9 2C4.875 2 1.5 5.375 1.5 9.5C1.5 13.625 4.875 17 9 17C13.125 17 16.5 13.625 16.5 9.5Z" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
					path(d="M8.99609 6.5H9.00283" stroke="#52A7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
					path(d="M8.99609 9.5V13.25" stroke="#52A7F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")

			b-tooltip(v-if="!isMobile && Object.keys(data.item).length != 0" custom-class="audience-table__info" :target="`info-${data.item.id}`" no-fade placement="bottom" triggers="hover")
				info-audience-tooltip(:item="data.item")

			b-modal(v-if="isMobile" scrollable="true" :id="`info-${data.item.id}`" content-class="modal-indents modal-audience" header-class="p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
				template(#modal-header="{ close }")
					icon-close(@click="close()")
				info-audience-tooltip(:item="data.item")

		template(v-slot:cell(id)='data')
			p.b3.audience-table__number(v-if="data.item && data.item.botViewUserId") {{ data.item.botViewUserId }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item && data.item.botViewUser && data.item.botViewUser.createdAt") {{ data.item.botViewUser.createdAt | dateDDMMMYYYY }}

		template(v-slot:cell(user)='data')
			div
				user-short-data(v-if='data.item.botViewUser && data.item.botViewUser.id' :item='data.item.botViewUser')
				span.text-warning(v-else) {{ $t('user.unknow') }}
			p.mb-0.text-sub.text-nowrap.audience-table__user-mail(v-if='data.item.email') {{ data.item.email }}

		template(v-slot:cell(bot)='data')
			.d-flex.flex-column.cursor-pointer.audience-table__bot(:id="!isMobile ? `info-bot-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`info-bot-${data.item.id}`) : ''")
				svg.audience-table__bot-not(v-if="!data.item.botView" width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
					path(d='M22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M9.16992 14.8299L14.8299 9.16992' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
					path(d='M14.8299 14.8299L9.16992 9.16992' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
				.position-relative(v-if="data.item.botView")
					img.rounded-circle(
						v-if="data.item && data.item.botView && data.item.bot.image && data.item.bot.image.contentUrl"
						:src="data.item.bot.image.contentUrl"
						alt="Bot Avatar"
						width="37"
						height="37"
					)
					span.rounded-circle.audience-table__bot-image(v-else)
						span {{ `${data.item.bot.username}`.slice(0,1) }}
					img.rounded-circle.audience-table__bot-social(src="/assets/img/icons/telegram.svg" alt="Telegram" width="16" height="16")
					span.d-block.rounded-circle.audience-table__bot-status(:class="data.item.botView.isActive() ? 'is-success' : 'is-error'")

			b-tooltip(v-if="!isMobile" :target="`info-bot-${data.item.id}`" custom-class="audience-bot" no-fade placement="bottomright" triggers="hover")
				div(v-if="data.item.botView" :item="data.item && data.item.botView")
					span.b3.d-block.audience-table__status.is-active(v-if="data.item.botView.isActive()") {{ $t('project.activeCard') }}
					span.b3.d-block(v-else) {{ $t('project.notActive') }}
					span.b3.d-block.text-grey(v-if="data.item.botView.botStatusUpdatedAt") {{ formatDate(data.item.botView.botStatusUpdatedAt) }}
				span(v-else) {{ $t('bot.notActivated') }}

			b-modal(v-if="isMobile" :id="`info-bot-${data.item.id}`" content-class="modal-indents modal-audience" header-class="p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
				template(#modal-header="{ close }")
					h3.h3 {{ $t('project.status') }}
					icon-close(@click="close()")
				div.mt-4(v-if="data.item.botView" :item="data.item")
					span.b1.d-block.audience-table__status.is-active(v-if="data.item.botView.isActive()") {{ $t('project.activeCard') }}
					span.b1.d-block(v-else) {{ $t('project.notActive') }}
					span.b1.d-block.text-grey(v-if="data.item.botView.botStatusUpdatedAt") {{ formatDate(data.item.botView.botStatusUpdatedAt) }}
				span.mt-4.b1(v-else) {{ $t('bot.notActivated') }}
				span.btn.btn-primary.modal-tooltip__btn(@click="$bvModal.hide(`info-bot-${data.item.id}`)") {{ $t('resources.btnFine') }}

		template(v-slot:cell(subscription)='data')
			.d-flex.align-items-start
				span.b3.d-inline-block.vuetable__status.audience-table__subscription-status(
					:class="{'text-success is-active': data.item.isActive() && data.item.plan && data.item.plan.duration,'text-primary': data.item.isStatusTrial(), 'text-grey': data.item.isCompleted() || data.item.isCancelledButNotKicked() || data.item.isCompletedSubscription(), 'is-default': !data.item.isActive() && !data.item.isStatusTrial() && !data.item.isCompleted() && !data.item.isCancelledButNotKicked(), 'is-default px-0': !data.item.isCompletedSubscription()&& !data.item.isStatusTrial() && (!data.item.isActive() || data.item.plan && !data.item.plan.duration) }"
				) {{ status(data.item) }}

			p.b4.mb-0.text-grey.audience-table__subscription-item(v-if="data.item.nextAt && !data.item.nextPaymentNull") {{ $t('forms.until') }} {{ data.item.nextAt | dateDDMMMYYYY }}
			p.b4.mb-0.text-grey.audience-table__subscription-item(v-else-if="data.item.kickAt && !data.item.nextPaymentNull") {{ $t('forms.until') }} {{ data.item.kickAt | dateDDMMMYYYY }}
			p.b4.mb-0.text-grey.audience-table__subscription-item(v-else-if="data.item.kickedAt && !data.item.nextPaymentNull") {{ $t('forms.until') }} {{ data.item.kickedAt | dateDDMMMYYYY }}
			p.b4.mb-0.d-flex.align-items-center.text-nowrap.text-grey.audience-table__subscription-item(v-if="data.item.isOnSupport() && !data.item.hasPaymentPaid()") {{ $t('plan.nextPaymentInSupport') }}
				span.b4.mb-0.ml-2.vuetable__tooltip(:id="!isMobile ? `nextPaymentNull-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`nextPaymentNull-${data.item.id}`) : ''") ?
			p.mb-0.b4.text-grey.audience-table__subscription-item(v-if="data.item.nextAt && data.item.trial && !data.item.nextPaymentNull") {{ $t('plan.free') }}
			p.mb-0.b4.text-grey.audience-table__subscription-item(v-if="data.item.nextPaymentNull") {{ $t('plan.nextPaymentNull') }}

		template(v-slot:cell(plan)='data')
			span.b3.d-block.audience-table__period(v-if="data.item.plan && data.item.plan.title == 'Yearly subscription' || data.item.plan && data.item.plan.title == 'Monthly subscription'") {{ data.item.plan.title == 'Yearly subscription' ? $t('date.year') : $t('date.month') }}
			span.b3.d-block.audience-table__period(v-else-if="data.item.plan") {{ data.item.plan.title }}
			span.b4.text-grey.d-block(v-if="data.item.plan" v-html="data.item.plan.price.priceWithCurrency()")

		template(v-slot:cell(paymentsSum)='data')
			.d-flex.flex-column
				span.b3.text-nowrap.audience-table__income-item(v-if='data.item.counters && data.item.counters.other && data.item.counters.other.count > 0 && data.item.counters.other.toMerchant' v-html='data.item.counters.other.toMerchant.priceWithCurrency()')
				span.audience-table__income-item(v-if='data.item.counters && data.item.counters.world2ru && data.item.counters.world2ru.count > 0 && data.item.counters.world2ru.toMerchant' v-html='data.item.counters.world2ru.toMerchant.priceWithCurrency()')
				span.b3.text-nowrap.audience-table__income-item(v-if='data.item.counters && data.item.counters.other && data.item.counters.other.count == 0 && data.item.counters.world2ru && data.item.counters.world2ru.count == 0' v-html="`0&nbsp;${currency[data.item.price.currency]}`")
</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TableShowMore from './TableShowMore';
import UserShortData from '@/components/Common/UserShortData';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import { SERVICE_STRING } from '@/utils/string';
import { CURRENCY } from '@/models/money';
import { SUBSCRIPTION_STATUS } from '@/models/subscription';
import InfoAudienceTooltip from '@/components/Common/InfoAudienceTooltip';
import IconClose from "@/components/Common/IconClose";
import moment from 'moment';
import { User } from '@/models/user';

export default {
	name: 'AudienceTable',
	mixins: [
		TableMixin
	],
	components: {
		TableSearch,
		TableShowMore,
		UserShortData,
		ModalTooltip,
		InfoAudienceTooltip,
		IconClose
	},
	props : {
		role: {
			type: String,
			default: 'item'
		},
		filters: {
			type: Object,
			default: () => ({})
		}
	},
	data () {
		return {
			sortBy: 'createdAt',
			searchKeys: ['createdAt'],
			noLocalSorting: true,
			sorting: (ctx) => {
				let data = {};
				data[`order[${ctx.sortBy}]`] = 'desc';
				this.isbusy = true;
				this.getItems(data);
			},
			fields: [
				{
					key: 'info',
					label: ''
				},
				{
					key: 'id',
					label: this.$t('project.id')
				},
				{
					key: 'user',
					label: this.$t('user.title')
				},
				{
					key: 'bot',
					label: this.$t('project.bot')
				},
				{
					key: 'subscription',
					label: this.$t('finance.subscription')
				},
				{
					key: 'plan',
					label : this.$t('plan.title')
				},
				{
					key: 'paymentsSum',
					label: this.$t('project.profit')
				}
			],
			items: [],
		}
	},
	computed: {
		...mapGetters({
			totalItems	: 'finance/audienceTotal',
			audienceUsers: 'finance/audienceUsers',
			isPending 	: 'finance/isPendingAudience',
			lastOpenedProjectId: 'project/lastOpenedProjectId'
		}),
		serviceString() {
			return SERVICE_STRING;
		},
		currency() {
			return CURRENCY;
		},
		subscriptionStatus() {
			return SUBSCRIPTION_STATUS;
		}
	},
	mounted () {
		this.$root.$on('bv::tooltip::hide', bvEvent => {
			let arrId = this.items.map(x => `info-${x.id}`);

			if (arrId.includes(bvEvent.target.id))
				this.getItems();
		})
		this.$root.$on('bv::modal::hide', bvEvent => {
			let arrId = this.items.map(x => `info-${x.id}`);

			if (arrId.includes(bvEvent.target.id))
				this.getItems();
		})
		//this.getItems();
		/* bus.$on('tableAudienceExport', () => {
			this.exportSubscriptions()
		}); */
		bus.$on('tableAudienceRefresh', (query) => {
			this.searching(query);
		});
		this.searching();
	},
	created () {
		this.searching = _.debounce( ( query ) => {
			this.sorting(this);
		}, 400);
	},
	destroyed () {
		bus.$off('tableAudienceRefresh');
		//bus.$off('tableAudienceExport');
		this.$store.commit('finance/setAudienceTotal', 0);
	},
	methods : {
		...mapActions({
			get: 'finance/audienceTable',
			//exportSubs: 'finance/exportSubscriptions',
		}),
		getItems() {
			if ( this.filters.query && this.filters.query.value != null ) {
				this.filters.query.value = this.filters.query.value.replace(/^\@/g, '');
			}

			this.get(_.extend(this.parseFilters(), {page: this.currentPage, itemsPerPage: this.perPage}, {id: this.lastOpenedProjectId}))
				.then( v => {
					if ( this.currentPage == 1 ) this.items = [];

					this.items = this.items.concat(v);

					this.items.forEach(el => {
						this.audienceUsers.forEach(x => {
							if (el.botViewUserId == x.id) {
								el.botViewUser = new User(x);
							}
						});
					});
					this.isbusy = false;
					this.$emit('onGetItems', v);
				})
				.catch( v => this.isbusy = false );
		},
		localSorting(ctx) {},
		paymentsAmountRu(v) {
			return v + ' ' + SERVICE_STRING.ru_ends(v, this.$t('project.paymentsArray'));
		},
		status(item) {
			//if( item.isUndefined() ) return this.$t('statuses.unknown');
			if(item.isStatusTrial()) return this.$t('statuses.trial');
			if(item.isActive() && item.plan && item.plan.duration) return this.$t('statuses.active');
			if(item.isCompleted() || item.isCancelledButNotKicked() || item.isCompletedSubscription()) return this.$t('statuses.closed');
			if(!item.isActive() || item.plan && !item.plan.duration) return this.$t('statuses.withoutSubscription');

			return '';
		},
		subsStatus(item) {
			if(item.status == this.subscriptionStatus[0] && item.plan && item.plan.paymentInterval) return this.$t('statuses.active');
			if(item.status == this.subscriptionStatus[1]) return this.$t('statuses.closed');
			//if(item.status == this.subscriptionStatus[1]) return this.$t('statuses.withoutSubscription');
			if(item.status == this.subscriptionStatus[2]) return this.$t('statuses.trial');
			if(item.plan && !item.plan.paymentInterval) return this.$t('statuses.withoutSubscription');

			return this.$t('statuses.withoutSubscription');
		},
		formatDate(date) {
			return moment(date).format('DD.MM.YY HH:mm');
		},
		/* exportSubscriptions () {
			let options = {};

			_.each(this.filters, (f, key) => {
				options[key] = f.value || f.id;
			})

			options.page = 1;

			if ( this.currentPage > 1 ) {
				options.itemsPerPage = this.currentPage * options.itemsPerPage;
			}

			options[`order[${this.sortBy}]`] = this.sortDesc ? 'desc' : 'asc';
			this.exportSubs(options);
		} */
	}
}
</script>

<style lang='scss' scoped>
.audience-table {
	&__bot {
		width: 37px;

		&-not{
			margin-left: 6px;
			stroke: var(--grey-text-color);
		}

		&-social {
			position: absolute;
			top: -3px;
			left: -4px;
			border: 2px solid var(--foreground-color);;
		}

		&-status {
			position: absolute;
			bottom: -3px;
			right: -2px;
			border: 2px solid var(--foreground-color);;
			width: 13px;
			height: 13px;

			&.is-success {
				background-color: var(--brand-success);
			}

			&.is-error {
				background-color: var(--brand-error);
			}
		}

		&-image {
			background-color: var(--primary-bg-color);
			color: var(--brand-primary);
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	&__income {
		&-item {
			margin-bottom: 8px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&__period {
		margin-bottom: 6px;
	}

	&__token {
		margin-bottom: 6px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__subscription {
		&-status {
			padding: 1px 6.5px 2px;

			&.is-default {
				padding: 0;
				background-color: transparent;
			}

			&.is-tooltip {
				margin-bottom: 15px;

				&:last-of-type {
					margin-bottom: 0 !important;
				}
			}
		}

		&-item {
			margin-top: 5px;
		}

		&-count {
			margin-left: 5.5px;
		}
	}

	&__user-mail {
		margin-top: 8px;
	}

	&__number {
		margin-bottom: 6px;
	}

	&__status {
		&.is-active {
			color: var(--brand-success);
		}
	}
}
</style>
